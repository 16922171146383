import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import ScrollToTop from "./ScrollToTopButton";

const layoutStyles = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  fontFamily: 'IntrumFont, sans-serif',
};

const mainContentStyles = {
  flexGrow: 1,
};

const Footer = () => {
  const footerData = [
    {
      title: "Contact",
      links: [{ label: "Contact", url: "/Contact" }],
    },
    {
      title: "FAQs",
      links: [
        { label: "FAQs", url: "/Faqs" },
        {
          label: "Mortgage Charter FAQs",
          url: "https://cdn-manager.prd.mortgages.intrumuk.com/intrum_mortgages/text/document/mortgage_charter_faqs/1/mortgage_charter_faqs.pdf",
        },
      ],
    },
    {
      title: "Help & Resources",
      links: [{ label: "Help", url: "/Help" }],
    },
    {
      title: "Privacy notice",
      links: [
        {
          label: "Privacy Notice",
          url: "https://cdn-manager.prd.mortgages.intrumuk.com/intrum_mortgages/text/document/privacy_notice/1/privacy_notice.pdf",
        },
      ],
    },
  ];

  return (
    <Box component="div" sx={layoutStyles}>
      <Box component="main" sx={mainContentStyles}></Box>
      <ScrollToTop />
      <Box
        component="footer"
        sx={{
          backgroundColor: "#1D5271",
          color: "#FFF",
        }}
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          paddingY={{ xs: 5, md: 10 }}
          paddingX={{ xs: 2, md: 10 }}
        >
          {/* First Column */}
          <Grid item xs={12} md={6}>
            {footerData.slice(0, 2).map((item, idx) => (
              <Box key={idx} >
                <List dense sx={{
                  padding: 0,
                }}>
                  {item.links.map((link, idx) => (
                    <ListItem key={idx}>
                      <ListItemButton
                        component="a"
                        href={link.url}
                        target="_blank"
                        sx={{
                          color: "#FFF",
                          padding: 0,
                        }}
                      >
                        <ListItemText primary={link.label} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Grid>

          {/* Second Column */}
          <Grid item xs={12} md={6}>
            {footerData.slice(2).map((item, idx) => (
              <Box key={idx}>
                <List dense sx={{
                  padding: 0,
                }}>
                  {item.links.map((link, idx) => (
                    <ListItem key={idx}>
                      <ListItemButton
                        component="a"
                        href={link.url}
                        target="_blank"
                        sx={{
                          padding: 0,
                          color: "#FFF",
                        }}
                      >
                        <ListItemText primary={link.label} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Grid>          
        </Grid>
        <Box
          sx={{
            fontSize: "12px",
            mt: 2,
            mb: 2,
            ml: 10,
            mr: 10,
            width: '80%',
            fontStyle: 'italic'

          }}>
          Intrum Mortgages is a trading name of Intrum Mortgages UK Finance Limited. Registered in England & Wales No. 5859881.
          Its registered office is at Belvedere, 12 Booth Street, Manchester, M2 4AW.
          Intrum Mortgages UK Finance Limited is authorised and regulated by the Financial Conduct Authority (FCA number 459016).
          Intrum Mortgages UK Finance Limited is also part of the Intrum Group,
          whose registered UK office is at The Omnibus Building, Lesbourne Road, Reigate, Surrey RH2 7JP
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
