import React from "react";
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Chip,
  useMediaQuery,
  useTheme,
  Link,
} from "@mui/material";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const carouselItems = [
  {
    link: "/RebrandArticle",
    image:
      "https://cdn-manager.prd.mortgages.intrumuk.com/intrum_mortgages/image/picture/house_stock/1/house_stock.png",
    title: "Why has Mars Rebranded to Intrum Mortgages?",
    description:
      "In June, Mars Capital became Intrum Mortgages. We’ve written to all our customers to explain why we’ve changed our look and name – and to reassure them that their Mortgages and our dedication to great customer service remain the same.",
  },
  {
    link: "/FinanceControlArticle",
    image:
      "https://cdn-manager.prd.mortgages.intrumuk.com/intrum_mortgages/image/picture/house_street_stock/1/house_street_stock.png",
    title: "Take control of your finances",
    description:
      "With the cost of living crisis and higher interest rates, most people have found that their money isn’t stretching as far as it used to. Even when times are good, it makes sense to spring clean your finances from time to time. Here are some tips for making sure you’re on track financially…",
  },
  {
    link: "/PayingTooMuchArticle",
    image:
      "https://cdn-manager.prd.mortgages.intrumuk.com/intrum_mortgages/image/picture/house_money_stock/1/house_money_stock.png",
    title: "Am I paying too much?",
    description:
      "With the cost of living crisis and rising interest rates, many of us have been feeling that our money doesn’t stretch as far as it used to. As your mortgage payment is likely to be your biggest monthly expense, making sure you’re on the best possible deal can make a big difference.",
  },
];

function CardItem({ item }) {
  return (
    <Card
      sx={{
        height: 450,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardMedia
        component="img"
        height="160"
        image={item.image}
        alt={item.title}
        sx={{ objectFit: "cover" }}
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: 600,
            textAlign: "left",
            fontSize: { xs: "1rem", md: "1.25rem" },
          }}
        >
          {item.title}
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          color="text.secondary"
          sx={{ textAlign: "left", flexGrow: 1 }}
        >
          {item.description}
        </Typography>
        <Link
          href={item.link}
          target="_blank"
          rel="noreferrer"
          sx={{ mt: 1, textAlign: "left" }}
        >
          <Chip
            label="Read More"
            variant="outlined"
            clickable
            sx={{ alignSelf: "flex-start", color: "#000", }}
          />
        </Link>
      </CardContent>
    </Card>
  );
}

export default function Carousel() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ width: "100%" }}>
      <ResponsiveCarousel
        showThumbs={false}
        infiniteLoop
        useKeyboardArrows
        autoPlay
        showStatus={false}
        swipeable
        centerMode={!isMobile}
        centerSlidePercentage={33.33}
      >
        {carouselItems.map((item, index) => (
          <Box key={index} sx={{ px: 1, pb: 1 }}>
            <CardItem item={item} />
          </Box>
        ))}
      </ResponsiveCarousel>
    </Box>
  );
}
