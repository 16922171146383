import React from "react";
import { Box, Typography, Grid, Divider, Link, CardMedia } from "@mui/material";
import Layout from "../nav/Layout";
import Accordion from "../Accordion";
import Carousel from "../Carousel";
import Footer from "../nav/Footer";
export default function HomePage() {

  return (
    <>
      <Layout />
      <Box>
        <Box
          sx={{
            backgroundColor: "#FFF",
            padding: "40px 60px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontFamily: "Arial, sans-serif",
              fontSize: "24px",
              marginBottom: "16px",
              color: "#000",
            }}
          >
            We’ve Rebranded
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, fontSize: "20px", marginBottom: "16px", color: "#000" }}
          >
            Mars Capital has rebranded to Intrum Mortgages
          </Typography>
          <Box sx={{ display: "flex", flexDirection: { sm: "row", xs: "column" }, alignItems: "center" }}>
            <Typography
              variant="body1"
              sx={{
                fontSize: "16px",
                color: "#000",
                marginBottom: { xs: "20px", sm: "40px" },
                lineHeight: "1.6",
              }}
            >
              In September 2024 Mars Capital rebranded to Intrum Mortgages.
              Our new website is part of an exciting new chapter for us, following the acquisition of the business by credit management group Intrum, in 2023.
              We've changed our name, and we've got a new look too. So, welcome to Intrum Mortgages.
            </Typography>
            <CardMedia
              component="img"
              height="160"
              sx={{
                width: { xs: "80%", sm: "30%" },
                marginLeft: { sm: "20px", xs: 0 },
                marginTop: { xs: "16px", sm: 0 },
                alignSelf: { xs: "center", sm: "flex-start" },
              }}
              image="https://cdn-manager.prd.mortgages.intrumuk.com/intrum_mortgages/image/logo/mars_logo/1/mars_logo.png"
              alt="Mars, Formerly Intrum Logo"
            />
          </Box>

          {/* Information Grid Section */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {/* Left Column Items */}
              <InfoItem
                icon={
                  <div style={{ width: "40px", height: "40px" }}>
                    <CardMedia
                      component="img"
                      image="https://cdn-manager.prd.mortgages.intrumuk.com/intrum_mortgages/image/icon/pay/1/pay.png"
                      alt="Pay icon"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                }
                title="HOW DO I MAKE A PAYMENT?"
                linkText="Pay now"
                linkHref="https://deimos.marscapital.co.uk:98/"
              />
              <InfoItem
                icon={
                  <div style={{ width: "40px", height: "40px" }}>
                    <CardMedia
                      component="img"
                      image="https://cdn-manager.prd.mortgages.intrumuk.com/intrum_mortgages/image/icon/handshake/1/handshake.png"
                      alt="Handshake icon"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                }
                title="SUPPORT WITH REPAYMENTS"
                linkText="Get help with your money"
                linkHref="/Help#repayment-help"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* Right Column Items */}
              <InfoItem
                icon={
                  <div style={{ width: "40px", height: "40px" }}>
                    <CardMedia
                      component="img"
                      image="https://cdn-manager.prd.mortgages.intrumuk.com/intrum_mortgages/image/icon/question/1/question.png"
                      alt="Question icon"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                }
                title="GOT A QUESTION?"
                linkText="I have a query about my Mortgage"
                linkHref="/Faqs"
              />
              <InfoItem
                icon={
                  <div style={{ width: "40px", height: "40px" }}>
                    <CardMedia
                      component="img"
                      image="https://cdn-manager.prd.mortgages.intrumuk.com/intrum_mortgages/image/icon/list/1/list.png"
                      alt="List icon"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                }
                title="TAKE A LOOK AT SOME OF OUR USEFUL DOCUMENTS"
                linkText="Useful documents"
                linkHref="/Help"
              />
            </Grid>
          </Grid>
        </Box>

        {/* Most Asked Questions Section */}
        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            padding: "40px 60px",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, fontSize: "20px", marginBottom: "20px", color: "#000" }}
          >
            Questions and Answers
          </Typography>
          <Accordion />
        </Box>

        {/* Tips and Advice Section */}
        <Box
          sx={{
            backgroundColor: "#FFF",
            padding: "40px 60px",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, fontSize: "20px", marginBottom: "20px", color: "#000" }}
          >
            Tips and advice
          </Typography>
          <Carousel />
        </Box>
      </Box>

      <Footer />
    </>
  );
}

// Reusable Component for Each Information Item
const InfoItem = ({ icon, title, linkText, linkHref }) => (
  <Box sx={{ marginBottom: "32px" }}>
    <Typography
      variant="h6"
      sx={{
        fontWeight: 700,
        color: "#7C7A7A",
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {React.cloneElement(icon, {
          style: {
            width: "40px",
            height: "40px",
            marginRight: "8px",
          },
        })}
        {title}
      </div>
    </Typography>
    <Typography variant="body2" sx={{ fontSize: "14px" }}>
      <Link href={linkHref} underline="hover" sx={{ color: "#000", fontWeight: 600, marginLeft: "50px" }}>
        {linkText}
      </Link>
    </Typography>
    <Divider sx={{ marginY: 2, borderColor: "#E0E0E0" }} />
  </Box>
);
